.bcg-dynamic {
  position: fixed;
  top: 50vh;
  left: 50vw;
  /* left: -50%; */
  font-size: 2rem;
  transform: translate(-50%, -50%) rotate(-30deg);
  /* z-index: 100; */
}
.bcg-icon {
  /* padding: 32px; */
  margin: 32px;
  color: var(--yellow0-a20);
  transition: color 1s, filter 1s;
}
.bcg-icon:hover {
  color: var(--yellow0);
  filter: drop-shadow(0 0 16px var(--yellow0));
  transition: none;
}
.bcg-odd {
  animation: ltr 32s linear infinite;
}
.bcg-even {
  animation: rtl 32s linear infinite;
}

@keyframes ltr {
  0% {
    transform: translate(-12.5%);
  }
  100% {
    transform: translate(12.5%);
  }
}
@keyframes rtl {
  0% {
    transform: translate(12.5%);
  }
  100% {
    transform: translate(-12.5%);
  }
}
