/* * {
  border: 1px solid red;
} */
:root {
  --yellow0: #ffde33;
  --yellow1: #ffd600;
  --yellow2: #b29500;
  --yellow3: #8e7700;
  --yellow4: #725f00;
  --orange0: #ffa733;
  --orange1: #f9a825;
  --orange2: #b26500;

  --yellow0-a50: #ffde3a50;
  --yellow0-a20: #ffde3320;
  --yellow0-a10: #b9a02010;
  --orange0-a50: #ffa73350;
  --orange0-a20: #ffa73320;
  --orange0-a10: #ffa73310;
}

/* ======== elements ======== */
* {
  font-family: "Roboto Mono", monospace;
  box-sizing: border-box;
}
a {
  color: var(--orange1);
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a:active {
  color: var(--orange0);
}
body {
  background-color: black;
  /* overflow: hidden; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  display: none;
}
button {
  background: none;
  color: var(--orange1);
  border: 0.125rem var(--orange1) solid;
  border-radius: 100vw;
}
button:hover {
  background: var(--orange0-a20);
}
button:active {
  background: var(--orange0-a50);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
ul {
  list-style-position: inside;
}
/* ======== GLOBAL ATOMIC CLASSES ======== */

.dimmed {
  font-size: xx-small;
  opacity: 0.5;
}
.button-mui-icon {
  position: relative;
  z-index: 1;
  color: var(--orange0);
}
.button-mui-icon:hover {
  filter: drop-shadow(0 0 4px var(--orange0))
    drop-shadow(0 0 8px var(--orange0));
}
.lateral-part {
  width: 24rem;
}
/* ======== GLOBAL SPECIFIC CLASSES ======== */
